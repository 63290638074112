import { Carousel, Image } from "@global";
import { Container, P } from "@util/standard";
import React, { createRef } from "react";

import { MOBILE_BREAKPOINT } from "@util/constants";
import ReactSlick from "react-slick";
import { SanityGallery } from "@graphql-types";
import styled from "styled-components";

const NavContainer = styled(Container)`
  width: 100%;
  margin: auto;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const CarouselContainer = styled(Container)`
  width: 90%;
  margin: 2% auto 3% auto;

  flex-direction: column;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 100%;
    margin: 5% auto;
  }
  .slick-next {
    right: -60px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      right: -30px;
    }
  }
  .slick-prev {
    left: -60px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      left: -30px;
    }
  }
`;

interface Props {
  content: SanityGallery;
}

const GalleryCarousel = ({ content }: Props) => {
  const carouselRef = createRef<ReactSlick>();

  const handleClick = (index: number) => {
    if (carouselRef.current) {
      carouselRef.current.slickGoTo(index);
    }
  };
  return (
    <CarouselContainer>
      <Carousel sliderRef={carouselRef} hideDots>
        {content?.images?.map(image => {
          if (image == null) {
            return <P color="errorRed">Error loading content</P>;
          }
          return (
            <Image
              width="100%"
              height="400px"
              objectFit="contain"
              imageData={image?.asset?.gatsbyImageData}
              key={content?._key}
            />
          );
        })}
      </Carousel>
      <NavContainer>
        {content?.images?.map((image, index) => {
          if (image == null) {
            return <P color="errorRed">Error loading content</P>;
          }
          return (
            <Container
              width="100%"
              margin="5% 5px"
              onClick={() => handleClick(index)}
              key={index}
            >
              <Image
                width="100%"
                height="100%"
                objectFit="cover"
                imageData={image?.asset?.gatsbyImageData}
              />
            </Container>
          );
        })}
      </NavContainer>
    </CarouselContainer>
  );
};

export default GalleryCarousel;
